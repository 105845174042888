import './ContactUs.scss';

const ContactUs = () =>
	<>
		<p className="contactTitle">
			Contact Us
		</p>
		<div className="contactContent">
			<div className="serviceTimes fifty">
				<div className="verticalCenter">
					<div className="horizontalAlign">
						<div className='lineItem'>Pastor Aaron Webb</div>
						<div className='lineItem'>401 Gilford Ave, Suite 201</div>
						<div className='lineItem'>Gilford, NH 03249</div>
						<a href='tel:6035244788' className='telephone'>(603) 524-4788</a>
					</div>
				</div>
			</div>
			<div className="location fifty">
				<div className="horizontalAlign">
					<iframe
						className='map'
						title="map"
						referrerPolicy="no-referrer-when-downgrade"
						src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAS2XfPip-elfVP3Sa0c-Iyq2RD_UdAGJg
&q=Central+Baptist+Church+Gilford,NH"
						allowFullScreen>
					</iframe>
				</div>
			</div>
		</div>
	</>;

export default ContactUs;