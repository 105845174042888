import { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import logo from './top_logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import classNames from 'classnames';

import './MobileHeader.scss';
import MobileMenuButton from './MobileMenuButton';

const MobileHeader = () => {
	const [isMenuToggled, toggleMenu] = useState(false);
	return (
		<>
		<header>
			<div id="headerContent">
				<a href="https://centralbaptistnh.org">
					<img src={logo} alt="cross logo" />
				</a>
				<IconButton size="large" onClick={() => toggleMenu(!isMenuToggled)}>
					<MenuIcon className="menu" />
				</IconButton>
			</div>
		</header>
		<div className={classNames('expandableMenu', {'expanded': isMenuToggled})}>
			<MobileMenuButton label='HOME' path='/' toggleMenu={() => toggleMenu(false) } />
			<MobileMenuButton label='DOCTRINAL STATEMENT' path='doctrinalStatement' toggleMenu={ () => toggleMenu(false) } />
			<MobileMenuButton label='ABOUT US' path='aboutUs' toggleMenu={ () => toggleMenu(false) } />
			<MobileMenuButton label='THE GOSPEL' path='gospel' toggleMenu={ () => toggleMenu(false) } />
			<MobileMenuButton label='MEDIA' path='media' toggleMenu={ () => toggleMenu(false) } />
			<MobileMenuButton label='CONTACT US' path='contactUs' toggleMenu={ () => toggleMenu(false) } />
		</div>
		</>
	);
}

export default MobileHeader;