import './Article.scss';

const Article = ({title, children}) =>
	<div className="flexContainer">
		<div className='content'>
			<p className='title'>
				{title}
			</p>
			{children}
		</div>
	</div>;

export default Article;