import YoutubeChannelLink from '../../util/YoutubeChannelLink/YoutubeChannelLink';
import whoIsJesus from './assets/who-is-jesus.mp3';
import './Media.scss';

const Media = () =>
	<div className='mediaContent'>
		<p>
			Who Is Jesus Christ?
		</p>
		<audio controls>
			<source src={whoIsJesus} type="audio/mpeg" />
		</audio>
		<YoutubeChannelLink />
	</div>

export default Media;