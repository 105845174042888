import Article from '../../util/Article/Article';
import './Gospel.scss';

const Gospel = () =>
	<Article title='The Gospel'>
		<div className='gospel'>
			<p>
				Have you ever wondered how to get to heaven? The Bible has the answer.
			</p>

			<p>
				We must first understand that all men and women are sinners. Sin is defined in the Bible as falling
				short of God's holy standard by breaking His commandments in any way.
			</p>

			<div className="verseBlock">
				<p className="verse">
					Romans 3:23 For all have sinned, and come short of the glory of God;
				</p>

				<p className="verse">
					Romans 5:12 Wherefore, as by one man sin entered into the world, and death by sin; and so death
					passed upon all men, for that all have sinned:
				</p>
			</div>

			<p>
				We must realize that our sin has separated us from God.
			</p>

			<div className="verseBlock">
				<p className="verse">
					Isaiah 64:6 But we are all as an unclean thing, and all our righteousnesses are as filthy rags;
					and we
					all do fade as a leaf; and our iniquities, like the wind, have taken us away.
				</p>
			</div>

			<p>
				Then there is both a physical and spiritual death penalty for sin. Death is not annihilation but
				separation. The Bible teaches us that death is the just payment for our sin.
			</p>

			<div className="verseBlock">
				<p className="verse">
					Ezekiel 18:20 The soul that sinneth, it shall die.
				</p>

				<p className="verse">
					Romans 6:23 For the wages of sin is death; but the gift of God is eternal life through Jesus
					Christ our
					Lord.
				</p>

				<p className="verse">
					Mark 9:45 And if thy foot offend thee, cut it off: it is better for thee to enter halt into
					life, than
					having two feet to be cast into hell, into the fire that never shall be quenched:
				</p>
			</div>

			<p>
				The Bible teaches that God loves us and does not want us to die and go to hell and has made a way
				for us
				to be saved from our sin.
			</p>

			<div className="verseBlock">
				<p className="verse">
					John 3:16 For God so loved the world, that he gave his only begotten Son, that whosoever
					believeth in
					him should not perish, but have everlasting life.
				</p>

				<p className="verse">
					Romans 5:8 But God commendeth his love toward us, in that, while we were yet sinners, Christ
					died for
					us.
				</p>
			</div>

			<p>
				The Bible teaches that Jesus Christ is the Son of God who lived a perfect life as God incarnate and
				became a perfect sacrifice for our sin, rose from the dead the third day, and is coming again.
			</p>

			<div className="verseBlock">
				<p className="verse">
					Colossians 2:9 For in him dwelleth all the fulness of the Godhead bodily.
				</p>

				<p className="verse">
					Hebrews 1:1-3 God, who at sundry times and in divers manners spake in time past unto the fathers
					by the
					prophets, Hath in these last days spoken unto us by his Son, whom he hath appointed heir of all
					things,
					by whom also he made the worlds; Who being the brightness of his glory, and the express image of
					his
					person, and upholding all things by the word of his power, when he had by himself purged our
					sins, sat
					down on the right hand of the Majesty on high;
				</p>

				<p className="verse">
					1 Corinthians 15:3-4 For I delivered unto you first of all that which I also received, how that
					Christ died for our sins according to the scriptures; And that he was buried, and that he rose
					again
					the third day according to the scriptures:
				</p>
			</div>

			<p>
				We must turn to God and believe in Jesus Christ to be saved from our sin.
			</p>

			<div className="verseBlock">
				<p className="verse">
					Acts 20:21 Testifying both to the Jews, and also to the Greeks, repentance toward God, and faith
					toward our Lord Jesus Christ.
				</p>

				<p className="verse">
					John 1:12 But as many as received him, to them gave he power to become the sons of God, even to
					them that believe on his name:
				</p>

				<p className="verse">
					Romans 10:13 For whosoever shall call upon the name of the Lord shall be saved.
				</p>
			</div>
		</div>
	</Article>;

export default Gospel;