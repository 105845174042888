import Article from '../../util/Article/Article';

const DoctrinalStatement = () =>
	<Article title='What We Believe'>
		<p>
			We believe that the King James version of the Bible is the preserved Word of God in the English language, and
			that it is the sole authority for all faith and practice. We reject textual criticism and modern versions based
			on corrupt manuscripts.
		</p>

		<p>
			We believe that there is one God manifested in three Persons: Father, Son, and Holy Spirit. We believe that
			each Person of the Godhead is equal in omniscience and power, but with different offices. We believe that God is
			eternal without beginning or end.
		</p>

		<p>
			We believe that the Father is the Author of all things material and immaterial, that salvation originated in
			His mind, and that He bestowed Creation and Salvation into the hands of His Son.
		</p>

		<p>
			We believe that Jesus Christ is the Son of God, that He came in the flesh, was born of a virgin, lived a
			sinless life, died on the Cross for the sins of mankind, rose from the dead three days later, sits at the right
			hand of God the Father making intercession for His saints, and that He is coming again to rule the world in
			truth and righteousness. Among other things He is Creator, Savior and Lord and is the only Mediator between God
			and men.
		</p>

		<p>
			We believe that the Holy Spirit is the Executor of God's will who comes and dwells in men upon their
			salvation, and who works to conform men into the image of the Son. He currently is reproving the world of sin,
			righteousness, and judgment.
		</p>

		<p>
			We believe that all men are born sinners, condemned to a godless eternity in hell, and that God in mercy
			provided salvation through the merits of His Son Jesus Christ's death on the Cross.
		</p>

		<p>
			We believe that salvation is solely a work of God in the heart of man. Men cannot earn salvation; it is by
			grace through faith alone.
		</p>

		<p>
			We believe that salvation occurs when one repents towards God and believes in the Lord Jesus Christ.
		</p>

		<p>
			We believe that Christians indwelt by the Holy Spirit will show evidence of their salvation by obedience to
			the Holy Bible. This includes submitting to the authority of a good local church and its pastor, and living
			separated, holy lives that glorify God.
		</p>

		<p>
			We believe that the local church was established by Jesus Christ while here on earth as the pillar and
			ground of truth and that it is the only organization through which God works in propagating the Gospel,
			discipling and growing the saints, and guarding the truth.
		</p>

		<p>
			We believe that the only two ordinances of the church are believers' baptism by immersion and the Lord's
			supper.
		</p>

		<p>
			We believe that there are two offices in the local church: Pastors and Deacons. Pastors lead the church,
			deacons serve the church.
		</p>

		<p>
			We believe that the heavens and earth were created in six literal days. We reject the theory of evolution
			and any attempt to compromise with it, such as the Gap theory or theistic evolution.
		</p>

		<p>
			We believe that God created men and women.
		</p>

		<p>
			We believe that marriage is between one man and one woman. We reject all attempts to redefine marriage.
		</p>

		<p>
			This statement is not exhaustive but does contain the key elements of what we believe.
		</p>
	</Article>

export default DoctrinalStatement;