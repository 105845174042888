import { Link } from 'react-router-dom';
import React from 'react';
import './OutlineButton.scss';

const OutlineButton = ({ label, path }) =>
	<Link to={ path }>
		<button className="outlineButton">
			{label}
		</button>
	</Link>;

export default OutlineButton;