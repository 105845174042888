import logo from './top_logo.png';

import './Header.scss';
import MenuButton from '../Buttons/MenuButton/MenuButton';

var Header = () =>
	<header>
		<div id="headerContent">
			<a href="https://centralbaptistnh.org">
				<img src={logo} alt="cross logo" />
			</a>
			<div className="menuItems">
				<MenuButton labelText="HOME" path="/" />
				<MenuButton labelText="DOCTRINAL STATEMENT" path="doctrinalStatement" />
				<MenuButton labelText="ABOUT US" path="aboutUs" />
				<MenuButton labelText="THE GOSPEL" path="gospel" />
				<MenuButton labelText="MEDIA" path="media" />
				<MenuButton labelText="CONTACT US" path="contactUs" />
			</div>
		</div>
	</header>

export default Header;