import React from 'react';
import './Information.scss';

const Information = () =>
	<div className="information">
		<div className="serviceTimes fifty">
			<div className="verticalCenter">
				<div className="horizontalAlign">
					<div className="serviceTitle">Service Times</div>
					<div className="lineItem">Sunday School: 9:45 AM</div>
					<div className="lineItem">Sunday AM Service: 11:00 AM</div>
					<div className="lineItem">Sunday PM Service: 1:30 PM</div>
					<div className="lineItem">Wednesday Prayer Meeting: 7:00 PM</div>
				</div>
			</div>
		</div>
		<div className="location fifty">
			<div className="horizontalAlign">
				<iframe
					className='welcomeVideo'
					title="welcome video"
					referrerPolicy="no-referrer-when-downgrade"
					src="https://www.youtube.com/embed/VevUpPzL9r8?rel=0"
					allowFullScreen>
				</iframe>
			</div>
		</div>
	</div>

export default Information;