import './Footer.scss';
import { Link } from 'react-router-dom';

const Footer = () => {
	return (
		<footer className="footer">
			<Link to='/'>Home</Link>
			<span className="separator">|</span>
			<Link to='doctrinalStatement'>Doctrinal Statement</Link>
			<span className="separator">|</span>
			<Link to='aboutUs'>About Us</Link>
			<span className="separator">|</span>
			<Link to='gospel'>The Gospel</Link>
			<span className="separator">|</span>
			<Link to='media'>Media</Link>
			<span className="separator">|</span>
			<Link to='contactUs'>Contact Us</Link>
		</footer>
	);
}

export default Footer;