import React from 'react';
import OutlineButton from '../../../util/Buttons/OutlineButton/OutlineButton';
import './Hero.scss';

const Hero = () =>
		<div className="heroImage">
			<div className="heroContent">
				<div className="heading">WELCOME TO CENTRAL BAPTIST&nbsp;CHURCH</div>
				<div className="subHeading">An independent assembly in Gilford,&nbsp;NH</div>
				<OutlineButton label='About Us' path="aboutUs" />
			</div>
		</div>;

export default Hero;