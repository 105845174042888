import './FlexTwoColumn.scss';

const FlexTwoColumn = ({leftComponent, rightComponent, hasDividingLine}) =>
		<div className='flexTwoColumn'>
			<div className='leftComponent'>
				<div className="verticalCenter">
					<div className="horizontalAlign">
						{leftComponent}
					</div>
				</div>
			</div>
			<div className='rightComponent'>
				<div className='verticalCenter'>
					<div className="horizontalAlign">
						{rightComponent}
					</div>
				</div>
			</div>
		</div>;

export default FlexTwoColumn;