import DoctrinalStatement from './pages/DoctrinalStatement/DoctrinalStatement';
import Home from './pages/Home/Home';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from './util/Layout';
import AboutUs from './pages/AboutUs/AboutUs';
import Gospel from './pages/Gospel/Gospel';
import Media from './pages/Media/Media';
import ContactUs from './pages/ContactUs/ContactUs';
import ScrollToTop from './ScrollToTop';

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="doctrinalStatement" element={<DoctrinalStatement />} />
            <Route path="aboutUs" element={<AboutUs />} />
            <Route path="gospel" element={<Gospel />} />
            <Route path="media" element={<Media />} />
            <Route path="contactUs" element={<ContactUs />} />
            <Route path="who-is-jesus/who-is-jesus.html" element={<Navigate to='/media' />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
}
