import { Outlet } from "react-router-dom";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import MobileHeader from "./Header/MobileHeader";
import MobileFooter from "./Footer/MobileFooter";

const Layout = () => {
	let header = window.outerWidth < 1100 ? <MobileHeader /> : <Header />;
	let footer = window.outerWidth < 750 ? <MobileFooter /> : <Footer />;

	return (<>
		{header}
		<div style={{minHeight: 'calc(100vh - 250px)'}}>
			<Outlet />
		</div>
		{footer}
	</>);
}

export default Layout;