import YoutubeChannelLink from '../../util/YoutubeChannelLink/YoutubeChannelLink';
import Hero from './Hero/Hero';
import Information from './Information/Information';

const Home = () => 
	<>
      <Hero/>
      <Information/>
      <YoutubeChannelLink />
	</>

export default Home;