import './MobileFooter.scss';
import { Link } from 'react-router-dom';

const MobileFooter = () => {
	return (
		<footer className="mobileFooter">
			<div className='section'>
				<Link to='/'>Home</Link>
				<span className="separator">|</span>
				<Link to='doctrinalStatement'>Doctrinal Statement</Link>
				<span className="separator">|</span>
				<Link to='aboutUs'>About Us</Link>
			</div>
			<div className='section'>
				<Link to='gospel'>The Gospel</Link>
				<span className="separator">|</span>
				<Link to='media'>Media</Link>
				<span className="separator">|</span>
				<Link to='contactUs'>Contact Us</Link>
			</div>
		</footer>
	);
}

export default MobileFooter;