import Article from '../../util/Article/Article';
import FlexTwoColumn from '../../util/FlexTwoColumn/FlexTwoColumn';
import pastorImage from '../../images/pastor_cropped.jpg';
import './AboutUs.scss';

const AboutUs = () => {
	const pastorImageComponent = <img className='pastorImage' src={pastorImage} alt='Pastor Aaron Webb and his wife, Teresa' />;
	const pastorBio = 
			<p className='pastorBio'>
				Pastor Aaron Webb originally hails from Maine. He served in the US Army and afterward,
				attended college in Austin, Texas. He was saved there in 1995 and was called to preach
				and ordained in Houston, TX under the leadership of Pastor Mark Thrift and Parkwood Baptist Church.
				In August 2016, with the help and direction of Pastor Bobby Mitchell of Mid-Coast Baptist
				Church in Brunswick, Maine, Pastor Webb, his wife Teresa, and their four children came
				to serve at Central Baptist Church.
			</p>;
	return <Article title='About Us'>
		<p>
			Central Baptist Church is an independent Baptist church, constituted in 1995 in Laconia, NH,
			under the leadership of church planter Pastor Robert Mitchell. He was sent out under the
			authority of Valley Baptist Church in Edinburg, Virginia. In 2019, we sold our building on
			Laconia Road and today meet in a storefront in Gilford, NH.
		</p>

		<p>
			From the beginning, it has been our desire to glorify God through obedience to God's Word,
			God-honoring worship, Bible-centered preaching, and personal evangelism. In this day of
			societal unrest and turmoil, we still believe that the Bible is absolute and contains the
			answers to the issues of life, the most important being, where we will spend eternity.
		</p>

		<p className='ourPastorSection'>Our Pastor</p>
		<FlexTwoColumn leftComponent={pastorImageComponent} rightComponent={pastorBio}/>
	</Article>
}

export default AboutUs;