import React from 'react';
import ExternalLinkSolidButton from '../Buttons/SolidButton/ExternalLinkSolidButton';
import './YoutubeChannelLink.scss';

const YoutubeChannelLink = () =>
    <div className='youtubeChannelLinkContainer'>
        <ExternalLinkSolidButton
            label='CBC YouTube Channel'
            href='https://www.youtube.com/channel/UCHTnnOrDSDXQ213aLgcNCUg'
            target='_blank'
        />
    </div>;

export default YoutubeChannelLink;